import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import tw, { css, styled } from 'twin.macro'
import {step1,step2, step3} from '../data/steps.json'
import Layout from '../components/layout'
import GetAQuote from '../components/get-a-quote'
import '../components/Hero/style.css'
import ParallaxBG from '../images/ParallaxBG2.png'
import EliteBuilderLogo from '../components/svg/EliteBuilderLogo'
// import AddressInput from '../components/dealer-search/address-input'
// import MapBox from '../components/dealer-search/map-box'
import {
	useDealerSearch,
	useAddressSearch,
} from '../components/dealer-search/utils'
// import WarrantyIcon from '../components/svg/WarrantyIcon'
// import MineralsIcon from '../components/svg/MineralsIcon'
// import MgO3Icon from '../components/svg/MgO3Icon'
// import EliteBuilderLogo from '../components/svg/EliteBuilderLogo'
// import OzoneSwimTag from '../components/svg/OzoneSwimTag'
// import EcoSwimTag from '../components/svg/EcoSwimTag'
import CartonX3 from '../images/V2-Cartons-Purifier&Pro.png'
import BannerCarton from "../images/V2-Carton-MineralSwimBagNew.png"
import MineralSwimGenerator from '../components/svg/MineralSwimGenerator'
import MineralSwimDeadSeaMinerals from '../components/svg/MineralSwimDeadSeaMinerals'
import MineralSwimPhBalancerModule from '../components/svg/MineralSwimPhBalancerModule'
import MineralSwimPurifier from '../components/svg/MineralSwimPurifier'
import { AccordionIndex } from '../components/Accordian/AccordionIndex'
import NewPool from '../images/@Domain-Pools-Australia_1.jpg'
import ExistingPool from '../images/@Mineral-Pool-QLD_1.jpg'
import MineralSwimBag from '../images/MineralSwim_BagSingle_10kg.png'
import MineralSwimISO from '../images/V2-Carton-Mineral_ISO.png'
import { SwiperComponent } from '../components/Swiper/SwiperComponent'
import {testimonials} from "../data/testimonial"
import EliteLogo from '../components/svg/EliteLogo'
import Brochure from '../images/MineralSwimV2_Brochure_ONLINEonly.pdf';


const IndexPage = (props) => {
	const {
		slideData,
		formData,
		ozonePlusMineralsImage,
		systemImage,
		cleanerImage,
		mineralsImage,
		phControlImage,
		testimonyImage,
		customerCareImage,
		environmentImage,
		systemComparisonImage,
		howItWorks3000xImage,
		howItWorksStepsImage,
		howItWorks80Image,
		systemDiagramImage,
		scrollInstance
	} = props.data

	// const { seoTitle, seoKeywords, seoDescription, node_locale, slug } =
	// pageData.edges[0].node
	const slide = slideData.edges[0].node

	/** Find a Dealer */
	const [queryAddress, onQueryAddress] = useState('')
	const [selectAddress, onSelectAddress] = useState({
		name: '',
		coordinates: [],
	})
	const [selectType, onSelectType] = useState({
		name: 'Mineral Swim',
		type: 'mineralswim',
	})
	const addresses = useAddressSearch(queryAddress)
	const [dealer, setDealer] = useState({})
	const [selectDealer, onSelectDealer] = useState(0)
	const [isExpanded, setIsExpanded] = useState({});
	const handleSelectDealer = (i) => {
		setDealer(dealers[i])
		onSelectDealer(i)
	}
	const { dealers, loading } = useDealerSearch(
		selectType.type,
		selectAddress.coordinates,
		setDealer,
		'en-AU',
		3
	)

	const TestimonialImage = styled.div`
		background: url(${({ bg }) => `${bg[0]}`});
		height: 500px;
		${tw`block md:hidden bg-center bg-no-repeat bg-cover`}
	`

	const PoolMineralsImage = styled.div`
		background: url(${({ bg }) => `${bg[0]}`});
		height: 500px;
		${tw`block md:hidden bg-center bg-no-repeat bg-cover`}
	`
	const MobileBanner = styled.div`
		background: url(${({ bg }) => `${bg[0]}`});

		@media (max-width: 425px) {
			background: url(${({ bg }) => `${bg[1]}`});
			${tw`bg-center bg-no-repeat bg-cover mb-0!`}
		}
		${tw`bg-center bg-no-repeat bg-cover mb-0!`}
	`
	const TabletBanner = styled.div`
		background: url(${({ bg }) => `${bg[0]}`});
		${tw`bg-left bg-no-repeat bg-cover mb-0!`}
	`

	const testimonialImages = [
		'https://images.ctfassets.net/ftcnv5up9dxv/1i6Ads9jm1jBUl7PxiN6lI/8c236da68d41b7e8dac887cd7c051e25/Woman-with-flower.png?fit=crop&f=center&w=1920&h=1000&fm=jpg&fl=progressive&q=85',
	]

	const poolMineralsImage = [
		'https://images.ctfassets.net/ftcnv5up9dxv/3RKmrUxyTED8fPtCveRa19/a292f72827f4113d0b7d74c555730a6c/Poolside-Minerals.jpg?fit=crop&f=bottom_right&w=1700&h=2000&fm=jpg&fl=progressive&q=85',
	]

	const bannerMobileImage = [
		'https://images.ctfassets.net/ftcnv5up9dxv/2gXFoA3gnuUnIzwPVeUASx/c0aa9a864398e3faac326ddde18a50c1/Mineral-Swim-Mobile-view.jpg?fit=crop&f=bottom&w=1500&h=1900&fm=jpg&fl=progressive&q=85',
		'https://images.ctfassets.net/ftcnv5up9dxv/1Grdnms3tccZtvXjule47K/d7ce6427c27ca79a9847c0888610a9af/Mineral-Swim-Mobile-view-v3.jpg?fit=crop&f=top&w=425&h=660&fm=jpg&fl=progressive&q=90',
	]
	const bannerTabletImage = [
		'https://images.ctfassets.net/ftcnv5up9dxv/4ammaq3XlYTj8n7sIWDCBi/2aa9e84a6fc18f9ff45875c3fe6e036f/dual-purification-healthy-mineral-rich-pool-water-and-ozone-to-oxygen-purification-tablet.jpg?fit=crop&f=left&w=1024&h=512&fm=jpg&fl=progressive&q=85',
	]
	return (
		<Layout className="HomePage">
			<Helmet>
				<title>Mineral Swim by Maytronics - Experience The Dead Sea Mineral Pool</title>
				<meta
					name="description"
					content="Your Authentic Mineral Pool Experience. Maytronics, brings high-quality minerals to recreate the Dead Sea experience in your mineral pool."
				/>
				<meta
					name="og:site_name"
					content="Mineral Swim by Maytronics - Experience The Dead Sea"
				/>
				<meta name="keywords" content="Mineral Swim" />
				<link rel="canonical" href="https://mineralswim.com/" />
				<link
					rel="preconnect"
					href="https://maytronics.com.au/shop?brand=Ozone%2520Swim"
				/>
				<link rel="preconnect" href="https://maytronics.com.au/ozone-swim" />
				<link
					rel="preconnect"
					href="https://maytronics.com.au/shop?brand=Eco%2520Swim"
				/>
				<link rel="preconnect" href="https://maytronics.com.au/eco-swim" />
				<link rel="preconnect" href="https://maytronics.com.au/elite-builder" />				
			</Helmet>
			{/* <div className="Carousel bg-gray-800 w-full">
				<header tw="flex w-full justify-between items-center px-1 md:px-12 pt-2 md:pt-4 pb-0 md:pb-2">
					<div tw="mr-2 md:mr-0 text-white">
						<MineralSwimTag tw="h-14 sm:h-20 lg:h-24 mb-2 -mr-2 sm:-mr-3 md:-mr-4" />
					</div>
					<span tw="text-lg text-center md:text-left sm:text-xl md:text-3xl text-white font-medium md:font-normal font-display">
						{slide.hook}
					</span>
				</header>
				<MobileBanner
					className="block md:hidden relative z-20 w-full"
					css={css`
						height: calc(100vh - 63px) !important;
						max-height: 767px !important;
					`}
					bg={bannerMobileImage}
					alt="Image of dual purification process for a mineral pool using ozone to oxygen purification for healthy and mineral-rich pool water."
				>
					<div
						className="absolute z-20 inset-0 h-full w-full"
						css={css`
							.gatsby-image-wrapper {
								width: 100% !important;
								height: calc(100vh - 63px) !important;
								max-height: 767px !important;
							}
							img {
								object-position: ${'50% 50%'}!important;
							}
						`}
					>
						<GatsbyImage
							image={slide.backgroundImage.gatsbyImageData}
							className="hidden lg:block w-full"
							loading="eager"
							alt="Image of dual purification process for a mineral pool using ozone to oxygen purification for healthy and mineral-rich pool water."
						/>
					</div>
					<main className="relative z-30 flex flex-col md:flex-row w-full h-full py-24">
						<h1 tw="block md:hidden font-display font-extrabold text-3xl text-center text-white -mt-12 px-2 leading-9">
						Australia's only mineral pool system combining 100% Dead Sea minerals with Ozone purification
						</h1>

						<div
							className="button-group"
							tw="flex md:flex-wrap w-full justify-between sm:justify-start md:justify-end items-center space-x-2 md:self-end mt-auto -mb-24 pb-4 px-1.5 sm:px-2 md:pl-0 md:pr-2 text-gray-800 font-display font-semibold capitalize text-sm md:text-base"
						>
							<Link
								tw="bg-yellow-500 rounded px-1 sm:px-2 md:px-4 py-2 shadow-md mb-2 font-display md:font-sans font-semibold md:font-normal text-sm"
								to={slide.mainActionUrl}
							>
								{slide.mainActionText}
							</Link>
							<Link
								tw="bg-blue-500 rounded px-1 sm:px-2 md:px-4 py-2 shadow-md mb-2 font-display md:font-sans font-semibold md:font-normal text-sm"
								to={slide.secondActionUrl}
							>
								{slide.secondActionText}
							</Link>
							<a
								tw="bg-gray-200 rounded px-1 sm:px-2 md:px-4 py-2 shadow-md mb-2 font-display md:font-sans font-semibold md:font-normal text-sm"
								href={slide.downloads.file.url}
								download="true"
							>
								{slide.thirdActionText}
							</a>
						</div>
					</main>
				</MobileBanner>
				<TabletBanner
					className="hidden md:block lg:hidden relative z-20 w-full"
					css={css`
						height: calc(100vh - 63px) !important;
						max-height: 512px !important;
					`}
					bg={bannerTabletImage}
				>
					<main className="relative z-30 flex flex-col md:flex-row w-full h-full py-24">
						<div
							className="button-group"
							tw="flex md:flex-wrap w-full justify-between sm:justify-start md:justify-end items-center space-x-2 md:self-end mt-auto -mb-24 pb-4 px-1.5 sm:px-2 md:pl-0 md:pr-2 text-gray-800 font-display font-semibold capitalize text-sm md:text-base"
						>
							<Link
								tw="bg-yellow-500 rounded px-1 sm:px-2 md:px-4 py-2 shadow-md mb-2 font-display md:font-sans font-semibold md:font-normal text-sm"
								to={slide.mainActionUrl}
							>
								{slide.mainActionText}
							</Link>
							<Link
								tw="bg-blue-500 rounded px-1 sm:px-2 md:px-4 py-2 shadow-md mb-2 font-display md:font-sans font-semibold md:font-normal text-sm"
								to={slide.secondActionUrl}
							>
								{slide.secondActionText}
							</Link>
							<a
								tw="bg-gray-200 rounded px-1 sm:px-2 md:px-4 py-2 shadow-md mb-2 font-display md:font-sans font-semibold md:font-normal text-sm"
								href={slide.downloads.file.url}
								download="true"
							>
								{slide.thirdActionText}
							</a>
						</div>
					</main>
				</TabletBanner>
				<div
					className="hidden lg:block relative z-20 w-full"
					css={css`
						height: calc(100vh - 63px) !important;
						max-height: 767px !important;
					`}
				>
					<div
						className="absolute z-20 inset-0 h-full w-full"
						css={css`
							.gatsby-image-wrapper {
								width: 100% !important;
								height: calc(100vh - 63px) !important;
								max-height: 767px !important;
							}
							img {
								object-position: ${'50% 50%'}!important;
							}
						`}
					>
						<GatsbyImage
							image={slide.backgroundImage.gatsbyImageData}
							className="hidden md:block w-full"
							loading="eager"
							alt="Image of dual purification process for a mineral pool using ozone to oxygen purification for healthy and mineral-rich pool water."
						/>
					</div>
					<main className="relative z-30 flex flex-col md:flex-row w-full h-full py-24">
						<div className="absolute top-1/4 md:top-0 xl:-top-1/5 right-0 w-2/5 md:w-1/4 text-white transform md:translate-y-3/4 mr-8 opacity-90">
							<MgO3Icon className="md:px-4" />
						</div>
						<div className="hidden md:flex flex-col w-1/2 h-full items-center justify-center">
							<img
								src="https://images.ctfassets.net/ftcnv5up9dxv/3AlSWQxulzdASQFU6EV1mj/b9ec16e0742705682329b7c9dac35479/mineral-swim-brand.png?fm=png&fl=png8"
								alt="Mineral Swim 'LS' Brand Icon"
								className="opacity-70"
							/>
						</div>
						<div
							className="button-group"
							tw="flex flex-wrap w-full justify-end items-center space-x-2 md:self-end mt-auto -mb-24 pb-12 pr-12 text-gray-800 font-display font-semibold capitalize text-sm md:text-base"
						>
							<Link
								tw="bg-yellow-500 rounded px-4 py-2 shadow-md mb-2 font-sans font-normal"
								to={slide.mainActionUrl}
							>
								{slide.mainActionText}
							</Link>
							<Link
								tw="bg-blue-500 rounded px-4 py-2 shadow-md mb-2 font-sans font-normal"
								to={slide.secondActionUrl}
							>
								{slide.secondActionText}
							</Link>
							<a
								tw="bg-gray-200 rounded px-4 py-2 shadow-md mb-2 font-sans font-normal"
								href={slide.downloads.file.url}
								download="true"
							>
								{slide.thirdActionText}
							</a>
						</div>
					</main>
				</div>
			</div> */}
			<main className='w-screen overflow-hidden' data-scroll-container>
			<section className='w-full bg-cover bg-center font-display banner-background-image relative mb-16 xs:mb-0'
>
			<div className="hidden xl:block absolute top-0 w-screen h-full" style={{}}>
				<GatsbyImage
							image={props.data.indexBannerXLGatsby.nodes[0].gatsbyImageData}
							className="h-full"
							loading="eager"
							alt="Test"
						/>
				</div>
			<div className="hidden lg:block xl:hidden absolute top-0 w-screen h-full" style={{}}>
				<GatsbyImage
							image={props.data.indexBannerMDGatsby.nodes[0].gatsbyImageData}
							className="h-full"
							loading="eager"
							alt="Test"
							objectPosition={['right']}
						/>
				</div>
			<div className="block lg:hidden absolute top-0 w-screen h-full" style={{}}>
				<GatsbyImage
							image={props.data.indexBannerSMGatsby.nodes[0].gatsbyImageData}
							className="h-full"
							loading="eager"
							alt="Test"
						/>
				</div>
		<div className='max-w-screen-lg grid lg:grid-cols-12 mx-auto 2xl:pl-0 xl:pl-36 lg:pl-36 text-white h-full relative z-10'>
			<div className='lg:col-span-6 flex flex-col gap-10 lg:gap-10 pt-5 pb-16 md:pt-0 lg:py-0 justify-around lg:justify-center h-full text-center lg:text-left'>
				<div className='text-2xl xs:text-4xl leading-tight pb-28 md:pb-48 lg:pb-0'>
				<h1>MINERAL SWIM<span className='text-xl md:text-3xl align-text-top'>&trade;</span>
								<br/><span className='-mt-1 md:-mt-3'>BY MAYTRONICS</span></h1>
								<p className='leading-tight text-xs xs:text-base uppercase pb-10 lg:pb-0'>Natural Purification from the Dead Sea</p>
									</div>
												
				<div className='flex flex-col gap-3 text-2xs justify-center items-center lg:justify-start lg:items-start'>
								<p className='leading-tight md:w-4/5 text-xs xs:text-base md:text-xl lg:text-base lg:mb-10'>Experience the healing and<br/> skin-softening benefits of a <br/>Dead Sea Mineral Swim&trade; Pool.</p>
  {/* <button className='flex-grow rounded-xl py-2 w-40 md:w-48 font-bold bg-gray-900 hover:bg-white text-blue-500 uppercase'>Convert My Existing Pool</button>
  <button className='flex-grow rounded-xl py-2 w-40 md:w-48 font-bold bg-gray-900 hover:bg-white text-blue-500 uppercase'>Thinking of A New Pool</button> */}
	<Link to='/s/#existing-pool'  className='cursor-pointer text-center w-64 md:w-72 text-sm sm:text-base bg-white text-black rounded-md py-1 font-bold hover:text-white hover:bg-gray-800 uppercase'>Convert My Existing Pool</Link>
	<Link to='/s/#new-pool' className='cursor-pointer text-center w-64 md:w-72 text-sm sm:text-base bg-gray-900 text-white rounded-md py-1 font-bold hover:text-black hover:bg-gray-200 uppercase'>Thinking of A New Pool</Link>
</div>
				<div className='hidden w-full lg:w-screen lg:px-5 lg:flex flex-col items-center mb-16 lg:mb-0'>	
				</div>
			</div>
		</div>
		</section>
		<section className='hidden w-full xs:flex justify-center items-center z-10 -mt-16 md:-mt-32 relative mb-16 md:mb-20 lg:mb-0'>
		<img
      src={BannerCarton}
      alt="Mineral V2 Product"
      className="object-contain object-center h-40 md:h-64 relative z-20"
    />
		</section>
		{/* <section className="relative" style={{zIndex:0}}>
  <div className='iconfigure max-w-screen-xl mx-auto absolute flex justify-center items-center gap-1'
	style={{
		bottom: -160,
		left: 0,
		right: 0,
	}}
    data-scroll
    data-scroll-speed="-9"
   >    <img
		src={IconicLogo}
		alt="Mineral V2 Logo Composition"
		className="object-contain w-full"
	/>
  </div>
</section> */}
{/* <section className='relative z-20'>
  <div  className='w-full absolute flex justify-center items-center gap-8 md:gap-1 pt-48'
    data-scroll
    data-scroll-speed="-5.7"
		>       
    <img
      src={MineralSwimBag}
      alt="Mineral V2 Product"
      className="object-contain object-center h-24 md:h-48 z-20 -ml-20 md:-ml-0"
      data-scroll
      data-scroll-speed="-0.9"
      data-scroll-direction="horizontal"
    />
    <img
      src={MineralSwimISO}
      alt="Mineral V2 Product"
      className="object-contain object-center h-24 md:h-48 z-10 -mr-28 md:-mr-40"
      data-scroll
      data-scroll-speed="1.3"
      data-scroll-direction="horizontal"
    />
  </div>
</section> */}
			
{/* <div className='w-full max-w-screen-lg mx-auto md:h-96 lg:h-70vh' style={{
  backgroundImage: `url(${ParallaxBG})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundAttachment: 'fixed'
}}></div> */}

			{/* 
			<section
				style={{
					background: '#acb7c4',
				}}
				className="hidden md:block"
			>
				<div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-16">
					<div className="space-y-8 sm:space-y-12">
						<h2 className="font-display text-5xl text-white max-w-screen-lg mx-auto italic">
						Experience the exceptional benefits of natural ozone purification + 100% Dead Sea minerals in your own mineral pool. 
						</h2>
					</div>
				</div>
			</section> */}
				<div className='relative bg-white flex flex-col justify-center items-center md:pt-5 md:pb-16'>
				<section className="w-full px-4 text-gray-800 sm:px-6 lg:px-8"
				
				>
				<div className="flex flex-col md:flex-row max-w-screen-lg mx-auto text-center">
					<div className="flex flex-col justify-center items-center w-full text-lg mb-4 md:mb-0 font-display">
						<h2 className="text-2xl sm:text-3xl font-display font-extrabold md:font-medium uppercase text-blue-500">							
							<span>
							WHAT IS THE MINERAL SWIM<span className='text-xl md:text-2xl align-text-top'>&trade;</span> PURIFICATION SYSTEM?
							</span>
						</h2>
						<p className='text-sm mt-5'>
						For thousands of years, the Dead Sea has attracted visitors worldwide who want to enjoy the mineral-rich water's health and well-being benefits. <br/>Now, you can experience the remarkable benefits of the Dead Sea in your pool with the Maytronics Mineral Pool  Purification System. Designed specifically for Australian pools, it combines ozone–to–oxygen purification technology and 100% Dead Sea Minerals to create a crystal-clear, therapeutic swimming experience for your backyard pool like no other.
						</p>
					</div>					
				</div>
				<div className="text-gray-600">
				<div className="max-w-7xl pt-12 px-4 text-center sm:px-6 lg:px-8 mx-auto">
					<div className="space-y-8 sm:space-y-12">
					<ul  className="mx-auto grid grid-cols-2 gap-x-16 gap-y-8 md:gap-x-5 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 md:grid-cols-4 text-xs text-gray-700 pb-16 md:pb-0">
							
							<li className="px-2 flex flex-col justify-start items-center">
										<div className="transform transition duration-500 hover:scale-110">
											<MineralSwimPurifier className="mx-auto h-24 xs:h-32" />
													
									</div>
								</li>
								
								<li className="px-2 flex flex-col justify-start items-center">
										<div className="transform transition duration-500 hover:scale-110">
											<MineralSwimGenerator
												className="mx-auto h-24 xs:h-32"
												fill="#FFFFFF"
												accent="#FFFFFF"
											/>
													
									</div>
								</li>
								<li className="px-2 flex flex-col justify-start items-center">
										<div className="transform transition duration-500 hover:scale-110">
											<MineralSwimDeadSeaMinerals
												className="mx-auto mt-1 h-24 xs:h-32"
												fill="#FFFFFF"
												accent="#FFFFFF"
											/>
													
										</div>
									
								</li>
								<li className="px-2 flex flex-col justify-start items-center">
										<div className="transform transition duration-500 hover:scale-110">
											<MineralSwimPhBalancerModule
												className="mx-auto mt-1 h-24 xs:h-32"
												fill="#FFFFFF"
												accent="#FFFFFF"
											/>
													
										</div>
								</li>
							</ul>
					</div>
				</div>
			</div>
			</section>
			</div>
		
			{/* <section className="flex w-full bg-white py-12 px-4 text-gray-800 sm:px-6 lg:px-8 lg:py-24">
				<div className="flex flex-col md:flex-row max-w-screen-lg mx-auto">
					<div className="flex flex-col space-y-8 text-lg mb-4 md:mb-0 md:mr-4 font-display">
						<h2 className="inline-flex text-2xl sm:text-4xl font-display font-extrabold md:font-medium uppercase text-blue-500">
							<img
								src="https://images.ctfassets.net/ftcnv5up9dxv/3AlSWQxulzdASQFU6EV1mj/b9ec16e0742705682329b7c9dac35479/mineral-swim-brand.png"
								alt="Mineral Swim 'LS' Brand Icon"
								className="inline-flex -mt-3 h-24 md:h-12 hidden md:block"
							/>{' '}
							<span>
								The Mineral Swim
								<span
									className="hidden md:inline font-sans font-light"
									role="img"
								>
									™
								</span>
								&nbsp;Experience
							</span>
						</h2>
						<p tw="order-3 md:order-2 font-medium md:font-normal">
							Say goodbye to the strong smell of chlorine, red stinging eyes and
							tight, dry skin. A Mineral Swim™ pool can make your whole body
							feel relaxed and rejuvenated after every swim! With Mineral Swim™
							you can recreate the relaxing, healthy magnesium mineral pool
							experience that people have enjoyed for thousands of years at the
							Dead Sea.
						</p>
						<p tw="font-medium md:font-normal">
							The Maytronics Mineral Swim™ Purification System – designed
							specifically for Australian pools – combines{' '}
							<strong>
								100% Dead Sea magnesium-rich minerals with natural
								ozone-to-oxygen water purification technology
							</strong>{' '}
							to create crystal clear, healthy, and therapeutic mineral swimming pool
							water.
						</p>
						<div tw="hidden md:block">
							<Link
								className="hidden md:inline-flex bg-yellow-500 rounded px-4 py-2 shadow-md font-sans font-normal"
								to={'#get-a-quote'}
							>
								Explore Products
							</Link>
						</div>
					</div>
					<div className="hidden md:block max-w-xs mx-auto">
						<GatsbyImage
							image={ozonePlusMineralsImage.nodes[0].gatsbyImageData}
							className="h-full"
							alt=""
						/>
					</div>
				</div>
			</section> */}

<section className='w-full bg-cover bg-center font-display mb-1 banner-purification' >
		<div className='max-w-screen-lg mx-auto h-full px-10 text-white flex w-full justify-end items-center lg:grid grid-cols-2 pb-10 md:pb-16'>
			<div id='how-it-works' className='flex flex-col justify-end lg:justify-center items-start lg:items-center w-full h-full'>
			<div className='text-base xs:text-3xl md:text-4xl leading-tight'>
				<h2 className='font-semibold'>HOW DOES<br /> MINERAL SWIM<span className='text-sm xs:text-2xl md:text-3xl align-text-top'>&trade;</span><br />	PURIFICATION<br /> WORK?</h2>
			</div>
			</div>
		</div>
			</section>
			<section className="relative w-full bg-gray-800 pt-12 pb-20 md:pt-20 px-8 text-white sm:px-6 lg:px-8 lg:py-24">
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 max-w-screen-lg mx-auto">
					<div className="space-y-6 text-lg mb-4 md:mb-0 md:mr-4 font-display px-1 w-full">
						<div className='leading-tight'>
						<h3 className="font-extrabold text-base uppercase">							
						DEAD SEA MINERALS
						</h3>
						</div>
						<p tw="text-sm md:h-40 overflow-auto">
						Dead Sea minerals have been renowned for their therapeutic benefits for thousands of years. Our minerals are sourced directly from the Dead Sea, a hypersaline lake in Israel with unique magnesium-rich mineral properties that offer a range of health, skin, and wellness benefits.
						</p>
						<div className="px-3 md:px-0 text-sm">
						<AccordionIndex items={step1} backGroundColor={'bg-blue-500'} activeBackGroundColor={'bg-white'}/>
						</div>
					</div>					
					<div className="mt-14 md:mt-0 flex flex-col space-y-6 text-lg mb-4 md:mb-0 md:mr-4 font-display px-1">
						<div className='leading-tight'>
						<h3 className="font-extrabold text-base uppercase">							
						OZONE PURIFICATION
						</h3>
						</div>
						<p tw="text-sm md:h-40 overflow-auto">
						Oxygen is converted to Ozone, which neutralises harmful contaminants in your pool. Combined with Mineral Swim&trade; 100% Dead Sea minerals, the purification system turns your pool into a crystal-clear, odour-free swimming experience.
						</p>
						<div className="px-3 md:px-0 text-sm">
						<AccordionIndex items={step3} backGroundColor={'bg-blue-500'} activeBackGroundColor={'bg-white'}/>						
						</div>
					</div>					
					<div className="mt-14 lg:mt-0 flex flex-col space-y-6 text-lg mb-4 md:mb-0 md:mr-4 font-display px-1">
						<div className='leading-tight'>
						<h3 className="font-extrabold text-base uppercase">							
						MINERAL SWIM OPTIMISATIONS
						</h3>
						</div>
						<p tw="text-sm md:h-40 overflow-auto">
						Enhance your Mineral Swim&trade; experience with optional upgrades and streamline your pool maintenance routine with Maytronics. Your Mineral Swim&trade; System delivers top-tier pool sanitation. Explore easy upgrades and enjoy these optimisations:
						</p>
						<div className="px-3 md:px-0 text-sm">
						<AccordionIndex items={step2} backGroundColor={'bg-blue-500'} activeBackGroundColor={'bg-white'}/>						
						</div>
					</div>					
				</div>
			</section>
			<section className="w-full py-12 px-4 text-gray-800 sm:px-6 lg:px-8 relative"
				>
				<div className="flex flex-col md:flex-row max-w-screen-lg mx-auto text-center">
					<div className="flex flex-col justify-center items-center w-full text-lg mb-4 md:mb-0 font-display gap-10">
						<div>
						<h2 className="text-2xl sm:text-4xl font-display font-extrabold md:font-medium uppercase text-blue-500">							
							<span>
							EXPLORE THE RIGHT MINERAL POOL SYSTEM FOR YOU
							</span>
						</h2>
						<p className='mt-5 text-sm font-medium mb-3'>
						Whether you seek ultimate relaxation, easy pool maintenance, or a therapeutic mineral swimming experience, our Mineral Swim&trade; range offers a solution. 
						</p>
						<p className='text-sm font-medium'>
						Designed and made in Australia for Australian pools, our Mineral Swim&trade; range blends the latest advanced technology of mineral pool systems with the natural benefits of Dead Sea minerals. Explore our range to discover how your pool can be transformed into a sanctuary of health and wellness.
						</p>
						</div>
						<div>
      <Link to='/compare/' className='border text-base text-blue-500 border-blue-500 rounded-md py-2 px-8 font-bold hover:text-blue-300 hover:bg-blue-500'>FIND THE RIGHT SYSTEM</Link>
    </div>
		<div className='w-full flex justify-center items-center md:-mt-3 -mb-10'>
		<img
      src={CartonX3}
      alt="Mineral V2 Product"
      className="object-contain object-center h-56 md:h-80"
    />
		</div>
					</div>					
				</div>
				</section>
				<div className='mx-auto max-w-screen-lg'>
				<hr className='mx-5' style={{ height: '1px', border: 'none', backgroundImage: 'linear-gradient(to right, white, gray, white)' }} />
				</div>
					{/* <div className='w-full flex justify-center -mt-12 mb-5 md:-mb-5'>
					<img
						src={MineralSwimBag}
						alt="Mineral V2 Product"
						className="object-contain h-32 md:h-48"
					/>
				<img
						src={MineralSwimISO}
						alt="Mineral V2 Product"
						className="object-contain h-32 md:h-56"
					/>
					</div>
			<GetAQuote
				{...{
					formData,
					systemImage,
					cleanerImage,
					mineralsImage,
					phControlImage,
					queryAddress,
					onQueryAddress,
					addresses,
					selectAddress,
					onSelectAddress,
					dealer,
				}}
			/> */}
			{/* <section className="relative w-full flex flex-col" id="testimony">
				<div className="hidden md:block absolute h-full w-full inset-0">
					<GatsbyImage
						image={testimonyImage.nodes[0].gatsbyImageData}
						className="h-full w-full"
						objectPosition="50% 90%"
						alt="A person swimming in a crystal-clear mineral pool with a view of palm trees and a blue sky in the background. The pool is filled with mineral-rich water and is surrounded by lounge chairs and umbrellas."
					/>
				</div>
				<div className="hidden md:block relative w-4/5 md:w-2/3 lg:w-1/3 h-full ml-14 mt-14 md:mt-24 mb-64 md:mb-32 bg-white bg-opacity-80 border-b border-gray-100 shadow-lg px-6 pt-6 pb-6 rounded">
					<q
						className="relative block ml-14 leading-5 text-gray-800 font-display"
						css={css`
							:before {
								position: absolute;
								margin-left: -3.75rem;
								margin-top: -0.25rem;
								padding-right: 0.25rem;
								width: 3.5rem;
								color: rgba(88, 200, 223, 1);
								top: 0;
								right: 0;
								bottom: 0;
								left: 0;
								content: url('data:image/svg+xml,%3Csvg%20fill%3D%22%2358C8DF%22%20viewBox%3D%220%200%2043%2026%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M11.06%2C7.88c1.94%2C0.47%2C3.74%2C1.43%2C5.22%2C2.78c1.4%2C1.54%2C2.17%2C3.56%2C2.17%2C5.65s-0.77%2C4.11-2.17%2C5.65c-1.26%2C1.37-2.89%2C2.35-4.68%2C2.82c-1.79%2C0.47-3.68%2C0.4-5.45-0.18c-1.66-0.56-3.12-1.61-4.19-3.02c-1.07-1.41-1.7-3.1-1.81-4.87C-0.46%2C9.64%2C5.78%2C3.47%2C12.2%2C1.65c0.7-0.21%2C1.39-0.38%2C2.07-0.52c0.35-0.08%2C0.71-0.08%2C1.06%2C0c-1.16%2C0.7-2.14%2C1.67-2.86%2C2.82c-0.72%2C1.16-1.16%2C2.47-1.28%2C3.83%22%2F%3E%3Cpath%20d%3D%22M38.87%2C1.25C37.71%2C1.9%2C36.73%2C2.83%2C36%2C3.95c-0.72%2C1.12-1.16%2C2.41-1.27%2C3.75c1.06%2C0.44%2C2.07%2C0.82%2C3.04%2C1.3c1.14%2C0.6%2C2.11%2C1.47%2C2.85%2C2.53s1.21%2C2.28%2C1.39%2C3.57c0.18%2C1.28%2C0.05%2C2.59-0.38%2C3.82c-0.42%2C1.22-1.13%2C2.33-2.06%2C3.22c-1.29%2C1.31-2.92%2C2.22-4.7%2C2.63s-3.64%2C0.28-5.36-0.35c-1.63-0.54-3.05-1.57-4.09-2.95c-1.04-1.38-1.63-3.04-1.71-4.77C22.86%2C9.56%2C29.08%2C3.7%2C34.73%2C1.86c1.01-0.33%2C2.07-0.59%2C3.09-0.84c0.32-0.03%2C0.65-0.03%2C0.97%2C0L38.87%2C1.25z%22%2F%3E%3C%2Fsvg%3E');
							}
							:after {
								display: none;
							}
						`}
					>
						Just when I thought my pool could not get any better, I converted it
						from saltwater to a Mineral Swim system and hallelujah! I&lsquo;m so
						happy I did! My pool is defintely cleaner, clearer and feels softer
						on my skin... Now that I have a Mineral Swim pool I&lsquo;m
						convinced this is the way pools are meant to be and I&lsquo;m sure
						that in the future, mineral systems like this will become the new
						standard.
						<span className="text-right block mt-4 text-gray-600">
							~ <b>Lana, Three Birds Renovations</b>
						</span>
					</q>
				</div>
				<div tw="block md:hidden bg-dolphin-gray-blue py-6 px-8">
					<p tw="text-white font-display italic font-semibold">
						"Just when I thought my pool could not get any better, I converted
						it from saltwater to a Mineral Swim system and hallelujah! I&lsquo;m
						so happy I did! My pool is defintely cleaner, clearer and feels
						softer on my skin... Now that I have a Mineral Swim pool I&lsquo;m
						convinced this is the way pools are meant to be and I&lsquo;m sure
						that in the future, mineral systems like this will become the new
						standard."
						<span className="text-white md:text-right text-white block mt-4 not-italic">
							<b>Lana, Three Birds Renovations</b>
						</span>
					</p>
				</div>
				<TestimonialImage bg={testimonialImages} alt="A person swimming in a crystal-clear mineral pool with a view of palm trees and a blue sky in the background. The pool is filled with mineral-rich water and is surrounded by lounge chairs and umbrellas."></TestimonialImage>
			</section> */}

			{/* <section
				id="health-and-wellbeing"
				className="flex w-full bg-white py-8 px-4 text-gray-800 sm:px-6 lg:px-8 lg:py-24"
			>
				<div className="flex max-w-screen-lg mx-auto">
					<div className="flex flex-col space-y-8 text-lg font-display">
						<h2 className="inline-flex text-2xl sm:text-4xl font-display font-extrabold md:font-bold uppercase text-blue-500">
							<img
								src="https://images.ctfassets.net/ftcnv5up9dxv/3AlSWQxulzdASQFU6EV1mj/b9ec16e0742705682329b7c9dac35479/mineral-swim-brand.png"
								alt="Mineral Swim 'LS' Brand Icon"
								className="hidden md:inline-flex -mt-3 h-24 md:h-12"
							/>{' '}
							HEALTH & WELLBEING BENEFITS
						</h2>
						<div className="grid md:grid-cols-3 gap-6 md:gap-10">
							<div className="h-full">
								<h3 className="font-display font-extrabold text-mineral-teal md:font-semibold text-xl mb-4 uppercase md:h-20">
									Safe for allergy,<br></br>asthma & eczema Sufferers
								</h3>
								<p className="mb-4 font-medium md:font-normal">
									Natural ozone purification combined with Dead Sea minerals
									greatly reduces the need for chemicals and chlorine which can
									cause flare-ups in allergy, asthma and eczema sufferers. Ozone
									purification is a great option for babies and young children.   
								</p>
								<Link
									className="hidden md:inline-flex bg-yellow-500 rounded px-4 py-2 mt-auto shadow-md font-sans font-normal"
									to={'#find-a-dealer'}
								>
									Find a Dealer
								</Link>
							</div>
							<div>
								<h3 className="font-display font-extrabold text-mineral-teal md:font-semibold text-xl mb-4 uppercase md:h-20">
									Soothes & relaxes muscles & joints
								</h3>
								<p className="mb-4 font-medium md:font-normal">
								Mineral Swim™ 100% Dead Sea minerals are rich in magnesium salt – known for maintaining healthy muscles. This could help with arthritis, mobility, leg cramps & pain. Your muscles will feel soothed & relaxed after every swim. A Mineral Swim™ pool is perfect after strenuous exercise.
								</p>
								<Link
									className="hidden md:inline-flex bg-yellow-500 rounded px-4 py-2 mt-auto shadow-md font-sans font-normal"
									to={'#get-a-quote'}
								>
									Explore Products
								</Link>
							</div>
							<div>
								<h3 className="font-display font-extrabold text-mineral-teal md:font-semibold text-xl mb-4 uppercase md:h-20">
									Soft on skin, <br className="hidden md:block" />
									gentle on eyes
								</h3>
								<p className="font-medium md:font-normal">
								Magnesium salt softens and enhances water quality making it much gentler than traditional salt and chlorine pools. Less irritated eyes, softer, silkier hair and skin. No more chlorine smell or need to shower afterwards. It’s like spa therapy.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section> */}

			{/* <section id="environment" className="w-full bg-white text-gray-800">
				<div
					className="relative w-full"
					css={css`
						height: calc(50vh - 15px);
						max-height: 767px;
					`}
				>
					<GatsbyImage
						image={environmentImage.nodes[0].gatsbyImageData}
						className="w-full h-full"
						objectPosition="50% 90%"
						alt="A stunning swimming pool with modern design elements and beautiful landscaping, perfect for your backyard oasis."
					/>

					<img
						src="https://images.ctfassets.net/ftcnv5up9dxv/2BHDhsccjovSqfgrA0CBxb/4a8177706659376d8f7c487c8432b428/Spasa_pool_of_the_year_icon.png"
						alt="SPASA Award"
						className="relative z-20 inline-flex -mt-44 md:-mt-36 ml-4 h-32 md:h-24"
						loading="lazy"
					/>
				</div>
				<div>
					<img
						src="https://images.ctfassets.net/ftcnv5up9dxv/1JkmmkiMXhD9sGk9tSiHsz/1426c4a3a47ac21e753a475926e314e7/ccc_banner.png"
						alt="Climate care certified"
						loading="lazy"
						tw="mt-4 px-4 block md:hidden"
					/>
				</div>
				<div className="flex max-w-screen-lg mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
					<div className="flex flex-col space-y-8 text-xl">
						<h2 className="inline-flex text-2xl sm:text-4xl font-display font-extrabold md:font-bold uppercase text-blue-500">
							<img
								src="https://images.ctfassets.net/ftcnv5up9dxv/3AlSWQxulzdASQFU6EV1mj/b9ec16e0742705682329b7c9dac35479/mineral-swim-brand.png"
								alt="Mineral Swim 'LS' Brand Icon"
								className="hidden md:inline-flex -mt-3 h-24 md:h-12"
								loading="lazy"
							/>{' '}
							The ENVIRONMENTAL BENEFITS
						</h2>
						<div className="grid md:grid-cols-2 gap-20">
							<div className="h-full font-display font-medium">
								<h3 className="font-display text-mineral-teal font-extrabold text-xl mb-2 uppercase">
									Climate Care Certified
								</h3>
								<p tw="text-lg font-medium">
									Mineral Swim™ is proud to be Climate Care Certified, a new
									efficiency and sustainability initiative of the Swimming Pool
									& Spa Association of Australia (SPASA). A Climate Care
									Certified product is a benchmark for swimming pools which
									utilises:
									<br />
									<br />
									+ Water efficiency/sustainability <br />+ Energy efficiency{' '}
									<br />+ Noise reduction measures <br />+ Environmentally
									sustainable designs <br />+ Efficiency/sustainability
									innovations
								</p>
								<img
									src="https://images.ctfassets.net/ftcnv5up9dxv/6ChuFsVYO4rc8hLppIitTZ/9c702240f219d2f0b4f96d31f5f4397b/cccc.png"
									alt="Climate Care Certified"
									className="mt-8 hidden md:block"
									loading="lazy"
								/>
							</div>
							<div className="grid grid-rows-2 gap-4 md:gap-10">
								<div tw="font-display font-medium">
									<h3 className="font-display text-mineral-teal font-extrabold text-xl mb-2 uppercase">
										Reduce water wastage
									</h3>
									<p tw="text-lg font-medium">
									Magnesium salt is an efficient flocculant which improves water clarity and can reduce the need for backwashing. With less harsh chemicals in the water the Mineral Swim™ pool water can be diluted and reused for garden irrigation, saving water, and reducing the amount of chemicals going down the drain.
									</p>
								</div>
								<div tw="font-display font-medium">
									<h3 className="font-display text-mineral-teal font-extrabold text-xl mb-2 uppercase">
										Reduce pool maintenance costs & INCREASE EQUIPMENT LIFE
									</h3>
									<p className="mb-4 text-lg font-medium">
										With less harsh chemicals than found in traditional
										chlorinated or salt swimming pools, your pool investment
										will last much longer. Saving you money on costly
										replacements of pool filters, pipes, and tiles.
									</p>
									<div className="group hidden md:inline-flex space-x-2 mt-auto">
										<Link
											className="bg-yellow-500 rounded px-4 py-2 shadow-md font-sans font-normal"
											to={'#get-a-quote'}
										>
											Explore Products
										</Link>

										<Link
											className="bg-yellow-500 rounded px-4 py-2 shadow-md font-sans font-normal"
											to={'#find-a-dealer'}
										>
											Find a Dealer
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}

			{/* <section
				className="hidden md:block relative z-20 grid w-full h-full bg-gray-800 bg-opacity-80"
				css={css`
					min-height: calc(70vh - 66px);
					max-height: 767px;
				`}
			>
				<div
					className="absolute z-20 inset-0 h-full w-full"
					css={css`
						.gatsby-image-wrapper {
							height: 100%;
						}
						img {
							object-position: 85% 35% !important;
							@media (min-width: 1024px) {
								object-position: 75% 35% !important;
							}
						}
					`}
				>
					<GatsbyImage
						image={customerCareImage.nodes[0].gatsbyImageData}
						alt=""
					/>
				</div>
				<div className="relative z-30 md:py-24 lg:w-1/2 xl:w-2/5 h-full flex flex-col items-start lg:justify-center text-white bg-gray-800 bg-opacity-70 px-12 py-6 text-lg">
					<WarrantyIcon className="h-24 mb-4" fill="#FFFFFF" accent="#FFFFFF" />
					<h2 className="text-3xl font-display font-bold mb-4">
						Maytronics Customer Care Plan
					</h2>
					<p className="mb-4 font-display">
					For complete peace of mind your Mineral Swim™ system comes with market leading warranty, dedicated support plus complementary mineral pool system health checks 6-weeks and 6-months after installation. All you need to do is relax and enjoy the Mineral Swim™ experience.
					</p>
					<p className="font-display">
						Register for your poolside health checks at
						<br />
						<Link to="/register/" tw="font-bold">
							mineralswim.com/register
						</Link>
					</p>
				</div>
			</section> */}

			{/* <section id="how-it-works" className="w-full bg-white text-gray-800">
				<div className="flex max-w-screen-lg mx-auto pb-0 pt-10 md:py-10 px-4 sm:px-6 lg:px-8 lg:py-24 bg-dolphin-grey md:bg-transparent">
					<div className="flex flex-col space-y-8 text-xl">
						<h2 className="hidden md:inline-flex text-4xl sm:text-4xl font-display font-medium uppercase text-blue-500">
							<img
								src="https://images.ctfassets.net/ftcnv5up9dxv/3AlSWQxulzdASQFU6EV1mj/b9ec16e0742705682329b7c9dac35479/mineral-swim-brand.png"
								alt="Mineral Swim 'LS' Brand Icon"
								className="hidden md:inline-flex -mt-3 h-24 md:h-12"
							/>{' '}
							HOW IT WORKS
						</h2>
						<h2 tw="uppercase font-display text-2xl font-extrabold text-blue-500 block md:hidden text-center mt-0!">
							How mineral swim purification works
						</h2>
						<div className="hidden md:flex justify-between items-center h-full w-full max-w-screen-lg mx-auto md:px-12 py-8 space-x-4">
							<GatsbyImage
								className="w-1/4"
								image={howItWorks3000xImage.nodes[0].gatsbyImageData}
								alt=""
							/>
							<GatsbyImage
								className="w-2/4"
								image={howItWorksStepsImage.nodes[0].gatsbyImageData}
								alt="A graphic representation of the four steps involved in a mineral pool system with ozone purification, including filtration, mineralization, oxidation, and sanitation."
							/>
							<GatsbyImage
								className="w-1/4"
								image={howItWorks80Image.nodes[0].gatsbyImageData}
								alt=""
							/>
						</div>
						<div className="grid md:grid-cols-2 gap-10 md:gap-20">
							<div className="flex md:hidden justify-between items-center h-full w-full max-w-screen-lg mx-auto space-x-4">
								<GatsbyImage
									className="w-full"
									image={howItWorksStepsImage.nodes[0].gatsbyImageData}
									alt="A graphic representation of the four steps involved in a mineral pool system with ozone purification, including filtration, mineralization, oxidation, and sanitation."
								/>
							</div>
							<div className="h-full font-display text-xl font-medium">
								<h3 className="font-display text-mineral-teal font-extrabold md:font-bold text-2xl mb-6 uppercase">
									OZONE-TO-OXYGEN PURIFICATION
								</h3>
								<p className="mb-4 text-lg">
									Ozone-to-oxygen sanitation is the smart environmentally
									friendly choice for swimming pools.{' '}
									<b className="font-semibold text-gray-800">
										It is up to 3,000 times more powerful than traditional water
										purification systems, reducing chlorine requirement by up to
										80%
									</b>
									, leaving you with clear, clean and odour free mineral pool water. Ozone instantly neutralises any organic contaminant and converts it into oxygen before the water hits your pool. Ozone purified water is noticeably softer, safer and more hygienic.
								</p>
								<Link
									className="hidden md:inline-flex bg-yellow-500 rounded px-4 py-2 shadow-md mt-auto font-sans font-normal"
									to={'#get-a-quote'}
								>
									Explore Products
								</Link>
							</div>
							<div className="-mt-4 md:mt-0 px-12 md:px-0 flex md:hidden justify-between items-center h-full w-full max-w-screen-lg mx-auto space-x-4">
								<GatsbyImage
									className="w-1/2"
									image={howItWorks3000xImage.nodes[0].gatsbyImageData}
									alt=""
								/>
								<GatsbyImage
									className="w-1/2"
									image={howItWorks80Image.nodes[0].gatsbyImageData}
									alt=""
								/>
							</div>
							<PoolMineralsImage
								tw="-mx-4 sm:-mx-6 md:hidden"
								bg={poolMineralsImage}
							/>
							<div className="h-full font-display text-xl font-medium">
								<h3 className="font-display text-mineral-teal font-extrabold md:font-bold text-2xl mb-4 uppercase">
									100% DEAD SEA MINERALS
								</h3>
								<p className="mb-4 text-lg font-medium">
								Recreate the relaxing, healthy magnesium mineral pool experience that people have enjoyed for thousands of years at the Dead Sea. Create a unique swimming experience in your backyard with just one ingredient – 100% Dead Sea Minerals.
									The flocculent effect of the{' '}
									<b className="font-semibold text-gray-700">
										magnesium-rich Dead Sea Minerals
									</b>{' '}
									clarifies the water. The magnesium salt will help with skin hydration and conditions such as psoriasis and eczema, leaving your skin feeling rejuvenated.
								</p>

								<Link
									className="hidden md:inline-flex bg-yellow-500 rounded px-4 py-2 shadow-md mt-auto font-sans font-normal"
									to={'#find-a-dealer'}
								>
									Find a Dealer
								</Link>
							</div>

							<div tw="bg-dolphin-gray-blue -mx-4 sm:-mx-6 md:hidden px-4 py-24 text-white font-display">
								<WarrantyIcon
									className="h-16 mb-10"
									fill="#FFFFFF"
									accent="#FFFFFF"
								/>
								<h2 className="text-2xl md:text-3xl font-display font-extrabold md:font-bold mb-4">
									Maytronics Customer Care Plan
								</h2>
								<p className="mb-4 text-lg font-medium font-display">
									For complete peace of mind your Mineral Swim™ system comes
									with market leading warranty, dedicated support plus
									complementary pool system health checks 6-weeks and 6-months
									after installation. All you need to do is relax and enjoy the
									Mineral Swim™ experience.
								</p>
								<p tw="text-lg font-medium font-display">
									Register for your poolside health checks at
									<br />
									<Link to="/register/" tw="font-bold">
										mineralswim.com.au/register
									</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</section> */}

			{/* <section tw="block md:hidden bg-white px-4 py-12">
				<h3 tw="text-blue-500 text-2xl font-display block md:hidden font-bold mb-8">
					Installation
				</h3>
				<img
					src="https://images.ctfassets.net/ftcnv5up9dxv/7mFc97VbIOTpXawCNyrXEN/2521d65e34054e3198605dc8f1d931cd/MineralSwim_PRO_System.jpg?w=600&h=600&fm=jpg&fl=progressive&q=85"
					alt="The Ecoclear Chlorinator is perfect for your mineral pool system, with a high-quality chlorinator cell for optimal performance"
				/>
			</section> */}

			{/* <section id="installation" className="w-full bg-gray-800 text-white">
				<GatsbyImage
					image={systemDiagramImage.nodes[0].gatsbyImageData}
					className="!w-full !h-auto max-h-screen hidden md:block"
					objectPosition="50% 55%"
					alt="The Ecoclear Chlorinator is perfect for your mineral pool system, with a high-quality chlorinator cell for optimal performance"
				/>
				<img
					alt="Mineral Swim System Diagram Mobile"
					className="md:hidden w-full -mt-1"
					src="https://images.ctfassets.net/ftcnv5up9dxv/6TwkIzIpHXonp4SaarCfox/659130ef7d97acab12882c92e17b0259/RENDER_Mobile.png?w=600&q=50"
					loading="lazy"
					style={{ objectPosition: '50% 50%' }}
				/>
				<div className="flex max-w-screen-lg mx-auto py-16 md:py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
					<div className="flex flex-col space-y-8 text-xl">
						<h2 className="hidden md:inline-flex text-4xl sm:text-4xl font-display font-extrabold md:font-medium uppercase text-blue-500">
							<img
								src="https://images.ctfassets.net/ftcnv5up9dxv/3AlSWQxulzdASQFU6EV1mj/b9ec16e0742705682329b7c9dac35479/mineral-swim-brand.png"
								alt="Mineral Swim 'LS' Brand Icon"
								className="inline-flex -mt-3 h-12"
								loading="lazy"
								style={{ objectPosition: '50% 50%' }}
							/>{' '}
							Installation
						</h2>
						<div className="grid md:grid-cols-2 gap-20" tw="mt-0!">
							<div className="flex flex-col h-full">
								<h3 className="font-display font-extrabold md:font-semibold text-2xl mb-6 uppercase">
									CONVERT to  Mineral Pool <br className="hidden md:block" />
									in less than an hour
								</h3>
								<p className="pb-4 font-display text-lg font-medium">
								Compatible with any existing pool sanitation system. The Mineral Swim™ O₃ Series can simply be added to your existing chlorinator. The Mineral Swim™ PRO can be used to replace all your existing equipment as it includes both a mineral chlorinator and dual ozone purification combined unit. Simply install the Mineral Swim™ control unit suitable for the size of your pool and add Mineral Swim™ Dead Sea Minerals.
								</p>
								<div className="pt-4">
									<Link
										to="#get-a-quote"
										className="inline-flex bg-yellow-500 rounded px-4 py-2 font-medium text-gray-800 font-display"
									>
										Get a Quote
									</Link>
								</div>
							</div>
							<div className="flex flex-col h-full">
								<h3 className="font-display font-extrabold md:font-semibold text-2xl mb-6 uppercase">
									Make your new pool <br className="hidden md:block" />a Mineral
									Swim
									<span className="font-sans" role="img">
										™
									</span>
									&nbsp;pool
								</h3>
								<p className="pb-4 font-display text-lg font-medium">
								Invest in a Mineral Swim™ pool for a lifetime of benefits. Maytronics recommends using a certified Elite Builder to install the very best new build mineral pool package combining the latest in pool optimisation, innovation, energy savings and high-quality products supplied by Maytronics. Find your nearest Elite Pool Builder below.
								</p>
								<div className="flex mt-auto items-end">
									<a
										href="https://maytronics.com.au/elite-builder"
										className="hidden md:initial bg-yellow-500 rounded px-4 py-2 font-medium text-gray-800 mt-auto font-display"
									>
										Find a Elite Builder
									</a>
									<a
										href="https://maytronics.com.au/elite-builder"
										className="bg-yellow-500 rounded px-4 py-2 font-medium text-gray-800 mt-auto font-display"
									>
										Find a Elite Builder
									</a>
									<EliteBuilderLogo className="inline-flex h-14 ml-4 -mb-2 text-white" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}
			{/* <section
				id="choosing-your-system-mobile"
				tw="block md:hidden w-full bg-white text-gray-800"
			>
				<div tw="bg-dolphin-grey-v2 px-4 pt-12">
					<h2 className="inline-flex text-2xl md:text-4xl sm:text-4xl font-display font-extrabold md:font-medium uppercase text-blue-500">
						<img
							src="https://images.ctfassets.net/ftcnv5up9dxv/3AlSWQxulzdASQFU6EV1mj/b9ec16e0742705682329b7c9dac35479/mineral-swim-brand.png"
							alt="Mineral Swim 'LS' Brand Icon"
							className="hidden md:3inline-flex -mt-3 h-24 md:h-12"
						/>{' '}
						CHOOSING YOUR POOL SYSTEM
					</h2>

					<div tw="block md:hidden">
						<img
							src="https://images.ctfassets.net/ftcnv5up9dxv/6XcgiNzjni4G2sB8qZkcXy/1416632d6c27ee00fb7d1576bbd56db1/MineralSwim_PRO_Series_Mobile.png?w=767&fl=progressive&q=90"
							alt="Mineral Swim 'LS' Brand Icon"
						/>

						<h3 tw="font-display uppercase font-extrabold md:font-bold border-t border-white text-xl text-center py-4 -mx-4 mt-6">
							Maytronics Mineral Swim Pro Series
						</h3>
						<div tw="-mx-4 overflow-scroll">
							<table tw="font-display border border-white border-collapse">
								<thead>
									<tr tw="border border-white">
										<th
											style={{ minWidth: '200px' }}
											tw="text-sm text-center! w-1/5 border border-white px-4 py-2 align-middle font-normal"
										>
											Model
										</th>
										<th
											style={{ minWidth: '200px' }}
											tw="text-sm text-center! w-1/5 border border-white px-4 py-2 align-middle font-normal"
										>
											Pool Size
										</th>
										<th
											style={{ minWidth: '200px' }}
											tw="text-sm text-center! w-1/5 border border-white px-4 py-2 align-middle font-normal"
										>
											Code
										</th>
										<th
											style={{ minWidth: '200px' }}
											tw="text-sm text-center! w-1/5 border border-white px-4 py-2 align-middle"
										>
											Mineral Swim PRO series includes:
										</th>
									</tr>
								</thead>
								<tbody>
									<tr tw="border border-white">
										<td tw="font-bold border text-sm text-center! border-white px-4 py-2 align-middle">
											PRO 1 series
										</td>
										<td tw="border text-sm text-center! border-white px-4 py-2 align-middle">
											up to 50000L
										</td>
										<td tw="border text-sm text-center! border-white px-4 py-2 align-middle">
											BR-600.01
										</td>
										<td tw="text-sm py-4 px-4 align-middle" rowSpan={3}>
											<ul>
												<li tw="flex">
													<svg
														tw="w-1/12 mt-1"
														width="20"
														height="16"
														viewBox="0 0 20 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M5.73659 12.7758L0.932996 7.53547C0.644405 7.22064 0.644405 6.71019 0.932996 6.39533L1.97809 5.25519C2.26669 4.94033 2.73463 4.94033 3.02322 5.25519L6.25915 8.78526L13.1902 1.2242C13.4787 0.909376 13.9467 0.909376 14.2353 1.2242L15.2804 2.36434C15.569 2.67917 15.569 3.18962 15.2804 3.50448L6.78172 12.7758C6.4931 13.0906 6.02518 13.0906 5.73659 12.7758Z"
															fill="black"
														/>
													</svg>
													<span tw="w-11/12 pl-2">
														Mineral Chlorinator &amp; Dual Ozone purification
														combined unit
													</span>
												</li>
												<li tw="flex">
													{' '}
													<svg
														tw="w-1/12 mt-1"
														width="20"
														height="16"
														viewBox="0 0 20 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M5.73659 12.7758L0.932996 7.53547C0.644405 7.22064 0.644405 6.71019 0.932996 6.39533L1.97809 5.25519C2.26669 4.94033 2.73463 4.94033 3.02322 5.25519L6.25915 8.78526L13.1902 1.2242C13.4787 0.909376 13.9467 0.909376 14.2353 1.2242L15.2804 2.36434C15.569 2.67917 15.569 3.18962 15.2804 3.50448L6.78172 12.7758C6.4931 13.0906 6.02518 13.0906 5.73659 12.7758Z"
															fill="black"
														/>
													</svg>
													<span tw="w-11/12 pl-2">
														Ecoclear® Shield &amp; Swim
													</span>
												</li>
												<li tw="flex">
													{' '}
													<svg
														tw="w-1/12 mt-1"
														width="20"
														height="16"
														viewBox="0 0 20 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M5.73659 12.7758L0.932996 7.53547C0.644405 7.22064 0.644405 6.71019 0.932996 6.39533L1.97809 5.25519C2.26669 4.94033 2.73463 4.94033 3.02322 5.25519L6.25915 8.78526L13.1902 1.2242C13.4787 0.909376 13.9467 0.909376 14.2353 1.2242L15.2804 2.36434C15.569 2.67917 15.569 3.18962 15.2804 3.50448L6.78172 12.7758C6.4931 13.0906 6.02518 13.0906 5.73659 12.7758Z"
															fill="black"
														/>
													</svg>
													<span tw="w-11/12 pl-2">Customer care plan</span>
												</li>
												<li tw="flex">
													{' '}
													<svg
														tw="w-1/12 mt-1"
														width="20"
														height="16"
														viewBox="0 0 20 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M5.73659 12.7758L0.932996 7.53547C0.644405 7.22064 0.644405 6.71019 0.932996 6.39533L1.97809 5.25519C2.26669 4.94033 2.73463 4.94033 3.02322 5.25519L6.25915 8.78526L13.1902 1.2242C13.4787 0.909376 13.9467 0.909376 14.2353 1.2242L15.2804 2.36434C15.569 2.67917 15.569 3.18962 15.2804 3.50448L6.78172 12.7758C6.4931 13.0906 6.02518 13.0906 5.73659 12.7758Z"
															fill="black"
														/>
													</svg>
													<span tw="w-11/12 pl-2">
														Mineral Swim™ welcome pack
													</span>
												</li>
											</ul>
										</td>
									</tr>
									<tr tw="border border-white">
										<td tw="font-bold border text-sm text-center! border-white px-4 py-2 align-middle">
											PRO 1 series
										</td>
										<td tw="border text-sm text-center! border-white px-4 py-2 align-middle">
											up to 50000L
										</td>
										<td tw="border text-sm text-center! border-white px-4 py-2 align-middle">
											BR-600.01
										</td>
									</tr>
									<tr tw="border border-white">
										<td tw="font-bold border text-sm text-center! border-white px-4 py-2 align-middle">
											PRO 1 series
										</td>
										<td tw="border text-sm text-center! border-white px-4 py-2 align-middle">
											up to 50000L
										</td>
										<td tw="border text-sm text-center! border-white px-4 py-2 align-middle">
											BR-600.01
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div tw="block md:hidden mt-24">
						<img
							src="https://images.ctfassets.net/ftcnv5up9dxv/5SmZTW991mnl8983sGrfQe/7ed290ca448bdac11f35e08d7cdf978c/MineralSwim_O3_Series_Mobile.png?w=767&fl=progressive&q=90"
							alt="Mineral Swim 'LS' Brand Icon"
						/>

						<h3 tw="font-display uppercase font-extrabold md:font-bold border-t border-white text-xl text-center py-4 -mx-4 mt-6">
							Maytronics Mineral Swim 0<sub>3</sub> Series
						</h3>
						<div tw="-mx-4 overflow-scroll">
							<table tw="font-display border border-white border-collapse">
								<thead>
									<tr tw="border border-white">
										<th
											style={{ minWidth: '200px' }}
											tw="text-sm text-center! w-1/5 border border-white px-4 py-2 align-middle font-normal"
										>
											Model
										</th>
										<th
											style={{ minWidth: '200px' }}
											tw="text-sm text-center! w-1/5 border border-white px-4 py-2 align-middle font-normal"
										>
											Pool Size
										</th>
										<th
											style={{ minWidth: '200px' }}
											tw="text-sm text-center! w-1/5 border border-white px-4 py-2 align-middle font-normal"
										>
											Code
										</th>
										<th
											style={{ minWidth: '200px' }}
											tw="text-sm text-center! w-1/5 border border-white px-4 py-2 align-middle"
										>
											Mineral Swim PRO series includes:
										</th>
									</tr>
								</thead>
								<tbody>
									<tr tw="border border-white">
										<td tw="border font-bold text-sm text-center! border-white px-4 py-2 align-middle">
											0<sub>3</sub> Series
										</td>
										<td tw="border text-sm text-center! border-white px-4 py-2 align-middle">
											up to 120,000 L
										</td>
										<td tw="border text-sm text-center! border-white px-4 py-2 align-middle">
											BR-600.04
										</td>
										<td tw="text-sm py-4 px-4 ">
											<ul>
												<li tw="flex">
													<svg
														tw="w-1/12 mt-1"
														width="20"
														height="16"
														viewBox="0 0 20 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M5.73659 12.7758L0.932996 7.53547C0.644405 7.22064 0.644405 6.71019 0.932996 6.39533L1.97809 5.25519C2.26669 4.94033 2.73463 4.94033 3.02322 5.25519L6.25915 8.78526L13.1902 1.2242C13.4787 0.909376 13.9467 0.909376 14.2353 1.2242L15.2804 2.36434C15.569 2.67917 15.569 3.18962 15.2804 3.50448L6.78172 12.7758C6.4931 13.0906 6.02518 13.0906 5.73659 12.7758Z"
															fill="black"
														/>
													</svg>
													<span tw="w-11/12 pl-2">
														Dual Ozone purification unit
													</span>
												</li>
												<li tw="flex">
													<svg
														tw="w-1/12 mt-1"
														width="20"
														height="16"
														viewBox="0 0 20 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M5.73659 12.7758L0.932996 7.53547C0.644405 7.22064 0.644405 6.71019 0.932996 6.39533L1.97809 5.25519C2.26669 4.94033 2.73463 4.94033 3.02322 5.25519L6.25915 8.78526L13.1902 1.2242C13.4787 0.909376 13.9467 0.909376 14.2353 1.2242L15.2804 2.36434C15.569 2.67917 15.569 3.18962 15.2804 3.50448L6.78172 12.7758C6.4931 13.0906 6.02518 13.0906 5.73659 12.7758Z"
															fill="black"
														/>
													</svg>
													<span tw="w-11/12 pl-2">
														Ecoclear® Shield &amp; Swim
													</span>
												</li>
												<li tw="flex">
													<svg
														tw="w-1/12 mt-1"
														width="20"
														height="16"
														viewBox="0 0 20 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M5.73659 12.7758L0.932996 7.53547C0.644405 7.22064 0.644405 6.71019 0.932996 6.39533L1.97809 5.25519C2.26669 4.94033 2.73463 4.94033 3.02322 5.25519L6.25915 8.78526L13.1902 1.2242C13.4787 0.909376 13.9467 0.909376 14.2353 1.2242L15.2804 2.36434C15.569 2.67917 15.569 3.18962 15.2804 3.50448L6.78172 12.7758C6.4931 13.0906 6.02518 13.0906 5.73659 12.7758Z"
															fill="black"
														/>
													</svg>
													<span tw="w-11/12 pl-2">Customer care plan</span>
												</li>
												<li tw="flex">
													<svg
														tw="w-1/12 mt-1"
														width="20"
														height="16"
														viewBox="0 0 20 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M5.73659 12.7758L0.932996 7.53547C0.644405 7.22064 0.644405 6.71019 0.932996 6.39533L1.97809 5.25519C2.26669 4.94033 2.73463 4.94033 3.02322 5.25519L6.25915 8.78526L13.1902 1.2242C13.4787 0.909376 13.9467 0.909376 14.2353 1.2242L15.2804 2.36434C15.569 2.67917 15.569 3.18962 15.2804 3.50448L6.78172 12.7758C6.4931 13.0906 6.02518 13.0906 5.73659 12.7758Z"
															fill="black"
														/>
													</svg>
													<span tw="w-11/12 pl-2">
														Mineral Swim™ welcome pack
													</span>
												</li>
											</ul>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</section> */}
			{/* <section
				id="choosing-your-system"
				className="w-full bg-white text-gray-800"
			>
				<div className="flex max-w-screen-lg mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:py-24 bg-dolphin-grey-v2 md:bg-white">
					<div className="flex flex-col space-y-8 text-xl">
						<h2 className="hidden md:inline-flex text-2xl md:text-4xl sm:text-4xl font-display font-bold md:font-medium uppercase text-blue-500">
							<img
								src="https://images.ctfassets.net/ftcnv5up9dxv/3AlSWQxulzdASQFU6EV1mj/b9ec16e0742705682329b7c9dac35479/mineral-swim-brand.png"
								alt="Mineral Swim 'LS' Brand Icon"
								className="hidden md:inline-flex -mt-3 h-24 md:h-12"
							/>{' '}
							CHOOSING YOUR POOL SYSTEM
						</h2>

						<div className="hidden md:block h-full w-full">
							<GatsbyImage
								image={systemComparisonImage.nodes[0].gatsbyImageData}
								className="h-full w-full"
								objectPosition="50% 50%"
								alt="Comparison chart of mineral swim pool systems - mineral swim pro series and mineral swim 03"
							/>
						</div>

						<Link
							className="hidden md:inline-flex bg-yellow-500 rounded px-4 py-2 shadow-md mx-auto my-6 font-sans font-normal"
							to={'#get-a-quote'}
						>
							Explore Products
						</Link>
						<h3
							tw="mt-0! md:mt-8!"
							className="font-display text-blue-500 md:text-mineral-teal font-extrabold md:font-semibold text-2xl mb-6 m uppercase"
						>
							POOL OPTIMISATION:
						</h3>
						<div className="grid md:grid-cols-3 text-base">
							<div className="flex flex-row md:flex-col h-full md:pr-5 font-display border-b md:border-none border-dolphin-light-gray items-center md:items-start">
								<div tw="hidden md:block">
									<h4 className="font-display text-dolphin-gray font-semibold text-xl mb-4 uppercase">
										Minerals
									</h4>
									<p className="mb-8">
										Maytronics recommends using only the Mineral Swim
										<span className="font-sans" role="img">
											™
										</span>
										&nbsp; minerals with your Mineral Swim system.
									</p>
								</div>
								<div tw="block md:hidden w-7/12 pr-4">
									<h4 className="font-display text-dolphin-gray font-bold text-xl mb-4 uppercase">
										Minerals
									</h4>
									<p className="mb-8 font-medium">
										Maytronics recommends using only the Mineral Swim
										<span className="font-sans" role="img">
											™
										</span>
										&nbsp; minerals with your Mineral Swim system.
									</p>
								</div>

								<div tw="block md:hidden w-5/12">
									<img
										src="https://images.ctfassets.net/ftcnv5up9dxv/1gARierUz3hRbVX6Lqy4xz/3a332adc1e08fa509118304c3a10f5e9/MineralSwim_Bag.png?w=200&fl=progressive&q=85"
										alt="minerals bag"
									/>
								</div>
								<a
									href="https://maytronics.com.au/product/mineral-swim-minerals"
									className="hidden md:block h-48 w-36 mt-auto mx-auto"
								>
									<GatsbyImage
										className="h-48 w-36 mt-auto mx-auto"
										objectPosition="50% 50%"
										image={mineralsImage.nodes[0].gatsbyImageData}
										alt=""
									/>
								</a>
								<a
									href="https://maytronics.com.au/product/mineral-swim-minerals"
									className="hidden md:inline-flex bg-yellow-500 rounded px-4 py-2 shadow-md mr-auto mt-4 mx-auto font-sans font-normal"
								>
									View Product
								</a>
							</div>
							<div className="flex flex-row md:flex-col h-full md:border-l-2 md:border-r-2 border-dolphin-gray md:px-5 py-6 md:py-0 font-display border-b border-dolphin-light-gray md:border-b-0 items-center md:items-start">
								<div tw="hidden md:block">
									<h3 className="font-display text-dolphin-gray font-semibold text-xl mb-4 uppercase">
										Cleaning
									</h3>
									<p className="mb-8">
										Maytronics offers the best in robotic pool cleaners.
										Class-leading robotic cleaning technology delivers
										unrivalled low-maintenance with maximum cleaning
										performance.
									</p>
								</div>
								<div tw="block md:hidden w-7/12 pr-4">
									<h3 className="font-display text-dolphin-gray font-bold text-xl mb-4 uppercase">
										Cleaning
									</h3>
									<p className="mb-0 md:mb-8 font-medium">
										Maytronics offers the best in robotic pool cleaners.
										Class-leading robotic cleaning technology delivers
										unrivalled low-maintenance with maximum cleaning
										performance.
									</p>
								</div>

								<div tw="block md:hidden w-5/12">
									<img
										src="https://images.ctfassets.net/ftcnv5up9dxv/2ZOvHSUm5N1I7FgZDaw2Ha/ccba1eb232949e2bcae52148da7b20a2/Dolphin_S300i_M600.png?w=200&fl=progressive&q=85"
										alt="S300i &amp; M600"
									/>
								</div>
								<a
									href="https://maytronics.com.au/product/dolphin-m-600"
									className="hidden md:block h-36 w-48 mt-auto mx-auto"
								>
									<GatsbyImage
										className="h-36 w-48 mt-auto mx-auto"
										objectPosition="50% 50%"
										image={cleanerImage.nodes[0].gatsbyImageData}
										alt=""
									/>
								</a>
								<a
									href="https://maytronics.com.au/product/dolphin-m-600"
									className="hidden md:inline-flex bg-yellow-500 rounded px-4 py-2 font-sans font-normal
									shadow-md mr-auto mt-4 mx-auto"
								>
									View Product
								</a>
							</div>
							<div className="flex flex-row md:flex-col h-full md:pl-5 font-display pt-6 md:pt-0 items-center md:items-start">
								<div tw="hidden md:block">
									<h3 className="font-display text-dolphin-gray font-semibold text-xl mb-4">
										pH AUTOMATION
									</h3>
									<p className="mb-8">
										Smart pool control, keeping you in touch with your pool’s
										every function and feature.
									</p>
								</div>
								<div tw="block md:hidden w-7/12 pr-4">
									<h3 className="font-display text-dolphin-gray font-bold text-xl mb-4">
										pH AUTOMATION
									</h3>
									<p className="mb-8  font-medium">
										Smart pool control, keeping you in touch with your pool’s
										every function and feature.
									</p>
								</div>
								<div tw="block md:hidden w-5/12">
									<img
										src="https://images.ctfassets.net/ftcnv5up9dxv/3ZrLC3aHP4033gRlltlf9n/7cdeafda87f4b7a7a8c0700e525ed71a/Mockup_pHiddy.png?w=200&fl=progressive&q=85"
										alt="ph automation"
									/>
								</div>
								<a
									href="https://maytronics.com.au/product/ph-drive"
									className="hidden md:block w-36 h-44 mt-auto mx-auto"
								>
									<GatsbyImage
										className="w-36 h-44 mt-auto mx-auto"
										objectPosition="50% 50%"
										image={phControlImage.nodes[0].gatsbyImageData}
										alt=""
									/>
								</a>
								<a
									href="https://maytronics.com.au/product/ph-drive"
									className="hidden md:inline-flex bg-yellow-500 rounded px-4 py-2 shadow-md mr-auto mt-4 mx-auto font-sans font-normal"
								>
									View Product
								</a>
							</div>
						</div>
					</div>
				</div>
			</section> */}

			{/* <GetAQuote
				{...{
					formData,
					systemImage,
					cleanerImage,
					mineralsImage,
					phControlImage,
					queryAddress,
					onQueryAddress,
					addresses,
					selectAddress,
					onSelectAddress,
					dealer,
				}}
			/> */}

<section id='new-pool' className='max-w-screen-lg mx-auto pt-20 md:py-20'>
<div className='text-blue-500 h-full grid md:grid-cols-2 px-5 md:px-10 lg:px-0 text-center md:text-left'>
  <div className='flex flex-col justify-center items-center md:justify-start md:items-start lg:pb-10 md:pb-0 pb-10 text-2xl gap-10 md:gap-10'>
    <h2 className='text-3xl md:text-4xl leading-tight'>BUILDING A NEW<br className='hidden md:block' /> POOL? MAKE IT A<br className='hidden md:block' /> MINERAL SWIM<span className='text-xl md:text-2xl align-text-top'>&trade;</span><br className='hidden md:block' /> POOL</h2>     
    <p className='text-gray-800 text-sm lg:pr-10'>Invest in a Mineral Swim&trade; pool for a lifetime of benefits. Maytronics recommends using a certified Elite Builder to install the very best new-build pool package, combining the latest in pool optimisation, innovation, energy savings, and high-quality products supplied by Maytronics. </p>
    <div>
      <Link to='https://www.maytronics.com.au/elite-builder/#find-a-builder' className='border-2 text-base text-blue-500 border-blue-500 rounded-md py-3 px-7 font-bold hover:text-blue-300 hover:bg-blue-500'>FIND AN ELITE BUILDER</Link>
    </div>
		<div>
					<EliteBuilderLogo className="h-20 lg:mb-16" color={"black"}/>
				</div>
  </div>
  <div className='h-70vh md:h-full flex justify-center items-center order-first md:order-none'>
  <div className="mb-10 md:mb-0 h-full px-5 md:pl-10 md:pr-0 flex-grow flex items-center justify-center">
    <img src={NewPool} alt="Mineral Swim Elite Builder House" className='h-full object-cover rounded-lg' />
  </div>
</div>
</div>

			</section>
			<div className='mx-auto max-w-screen-lg'>
				<hr className='mx-5' style={{ height: '1px', border: 'none', backgroundImage: 'linear-gradient(to right, white, gray, white)' }} />
				</div>
			<div className='w-full'>
			<section id='existing-pool' className='max-w-screen-lg mx-auto pt-10 md:py-20 text-center md:text-left'>
<div className='text-blue-500 h-full grid md:grid-cols-2 px-10 md:px-5 lg:px-0'>
<div className='h-70vh md:h-full flex justify-center items-center'>
  <div className="h-full md:px-5 flex-grow flex items-center justify-center mb-10 md:mb-0">
    <img src={ExistingPool} alt="Mineral Swim Elite Builder House" className='h-full w-auto object-cover rounded-lg' />
  </div>
</div>
  <div className='flex flex-col justify-center items-center md:justify-start md:items-start lg:pb-10 md:pb-0 pb-10 text-2xl gap-10 md:gap-10 md:pl-2 lg:pl-5 pr-0 md:pr-5 lg:pr-0'>
    <h2 className='text-3xl md:text-4xl leading-tight'>CONVERT TO A MINERAL POOL WITH MINERAL SWIM<span className='text-xl md:text-2xl align-text-top'>&trade;</span></h2>     
    <p className='text-gray-800 text-sm'>Transform your pool effortlessly. The Mineral Swim V2 Purifier seamlessly integrates with any existing pool sanitation system and can be controlled by your existing chlorinator. Opt for a Mineral SwimTM PRO system for a full mineral pool system upgrade with no additional chlorinator required. Consult your local Maytronics Elite Dealer for expert advice on the perfect fit and enhance your experience with 100% Dead Sea Minerals.</p>
<div>
      <Link to='https://www.maytronics.com.au/find-a-dealer/' className='border-2 text-base text-blue-500 border-blue-500 rounded-md py-3 px-7 font-bold hover:text-blue-300 hover:bg-blue-500'>FIND A SUPPLIER</Link>
    </div>
		<div>
					<EliteLogo className="h-20 lg:mb-16" color={"black"}/>
				</div>
  </div>
</div>

			</section>
			</div>
			<div className='mx-auto max-w-screen-lg'>
				<hr className='mx-5' style={{ height: '1px', border: 'none', backgroundImage: 'linear-gradient(to right, white, gray, white)' }} />
				</div>
				<section
				className="w-full bg-white text-gray-800 py-10 md:py-20"
			>				
						<div className="w-full flex flex-col md:flex-row text-base text-center gap-10 md:gap-0">
							
							<div className="flex-grow flex flex-row items-center justify-center md:border-r border-gray-400">
						<img
							src="https://images.ctfassets.net/ftcnv5up9dxv/3AlSWQxulzdASQFU6EV1mj/b9ec16e0742705682329b7c9dac35479/mineral-swim-brand.png"
							alt="Mineral Swim 'LS' Brand Icon"
							className="w-20 object-contain"
						/>{' '}
							<h2 className="text-xl md:text-3xl font-display font-extrabold md:font-medium uppercase text-blue-500 leading-tight text-left">
						TRUSTED BY COMPANIES.<br/> LOVED BY SWIMMERS!
					</h2>
							</div>
							<div className="flex-grow flex flex-col justify-center h-full md:border-r border-gray-400">
								<h3 className="font-display text-dolphin-gray text-base whitespace-nowrap px-4">
								FEATURED ON
								</h3>
								<h4 className="font-display text-blue-500 text-2xl uppercase">
									The
									Block
								</h4>
								<small>(Channel 9)</small>
							</div>
							<div className="flex-grow flex flex-col justify-center h-full md:border-r border-gray-400">
								<h3 className="font-display text-dolphin-gray text-base whitespace-nowrap px-4">
								FEATURED ON
								</h3>
								<h4 className="font-display text-blue-500 text-2xl uppercase">
									Healthy 
									Homes
								</h4>
								<small>(Channel 10)</small>
							</div>
							<div className="flex-grow flex flex-col justify-center h-full">
								<h3 className="font-display text-dolphin-gray text-base whitespace-nowrap">
									FEATURED ON
								</h3>
								<h4 className="font-display text-blue-500 text-2xl uppercase">
									Three Birds 
									Renovations
								</h4>
								<small>(YouTube)</small>
							</div>
						</div>		
			</section>
			{/* <section
				id="choosing-your-system"
				className="w-full bg-white text-gray-800"
			>
				<div className="flex max-w-screen-lg mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
					<div className="flex flex-col text-xl text-center">
						<h2 className="text-3xl sm:text-4xl font-display font-medium uppercase text-blue-500 mb-6">
							Trusted by companies. Loved by swimmers!
						</h2>
						<p className="mb-24">
							The Mineral Swim system has been approved by SPASA, appeared on
							The Block, Healthy Homes and Three Birds Renovations.
						</p>
						<div className="grid grid-cols-2 md:grid-cols-4 gap-10 text-base">
							<div className="flex flex-col h-full">
								<h3 className="font-display text-dolphin-gray font-semibold text-base mb-4 uppercase">
									MINERAL SWIM IS APPROVED BY
								</h3>
								<img
									src="https://images.ctfassets.net/ftcnv5up9dxv/7d0eX6zZT5bsFOnURaG1pR/416a96711436de87b354ab26b7062fa3/LOGO_SPASA_AUSTRALIA_BLUE.png?fm=png&fl=png8&w=200"
									alt="SPASA Australia"
									className="mx-auto"
									width="120"
								/>
							</div>
							<div className="flex flex-col h-full">
								<h3 className="font-display text-dolphin-gray font-semibold text-base mb-4 uppercase">
									As seen on
								</h3>
								<h4 className="font-display text-mineral-teal text-2xl mb-1 uppercase">
									The <br />
									Block
								</h4>
								<small>(Channel 9)</small>
							</div>
							<div className="flex flex-col h-full">
								<h3 className="font-display text-dolphin-gray font-semibold text-base mb-4 uppercase">
									As seen on
								</h3>
								<h4 className="font-display text-mineral-teal text-2xl mb-1 uppercase">
									Healthy <br />
									Homes
								</h4>
								<small>(Channel 10)</small>
							</div>
							<div className="flex flex-col h-full">
								<h3 className="font-display text-dolphin-gray font-semibold text-base mb-4 uppercase">
									As seen on
								</h3>
								<h4 className="font-display text-mineral-teal text-2xl mb-1 uppercase">
									Three Birds <br />
									Renovations
								</h4>
								<small>(YouTube)</small>
							</div>
						</div>
					</div>
				</div>
			</section> */}
			<section id='testimonials' className='bg-gray-800 flex flex-col'>
				<div className='shadow-2xl py-10 md:py-16 lg:px-0 px-5 md:px-3'>
				<h2 className='text-center text-2xl sm:text-4xl font-display font-extrabold md:font-medium uppercase text-blue-500'>TESTIMONIALS</h2>
<div className='main-swiper-container max-w-screen-lg mx-auto text-white md:-ml-2 lg:mx-auto'>
		<SwiperComponent data={testimonials}/>
		</div>
		</div>
		</section>


			<section className="w-full text-blue-500 py-20 text-center space-y-10">
				<h2 className='text-3xl mb-5'>LET’S START YOUR<br/> MINERAL SWIM<span className='text-xl align-text-top'>&trade;</span> JOURNEY</h2>
				<div className='max-w-screen-lg mx-auto flex flex-col md:flex-row justify-center items-center px-5 md:px-2 lg:px-5 gap-5 md:gap-3 lg:gap-5'>
				<Link to='/?action=download-brochure'>
      <button className='text-lg md:text-base lg:text-base text-white rounded-md py-3 px-7 md:px-4 lg:py-3 lg:px-7 font-bold bg-gray-900 hover:text-blue-300 hover:bg-blue-500'>DOWNLOAD A BROCHURE</button>
    </Link>
      <Link to="https://www.maytronics.com.au/support/find-a-dealer/" className='text-lg md:text-base lg:text-base text-white rounded-md py-3 px-7 md:px-4 lg:py-3 lg:px-7  font-bold bg-gray-900 hover:text-blue-300 hover:bg-blue-500'>FIND A NEARBY SUPPLIER</Link>
   
      <Link to="/compare/" className='text-lg md:text-base lg:text-md text-white rounded-md py-3 px-7 md:px-4 lg:py-3 lg:px-7  font-bold bg-gray-900 hover:text-blue-300 hover:bg-blue-500'>FIND THE RIGHT PACKAGE</Link>
	</div>
</section>
			{/* <section className="grid md:grid-cols-2 place-content-center w-full">
				<div className="grid bg-gray-600 px-6 py-12 text-white text-center place-content-center w-full">
					<OzoneSwimTag className="h-24 lg:h-32 mx-auto text-white mb-8" />
					<p className="text-lg mb-6 max-w-lg mx-auto font-display">
						Harnessing Ozone, natures’ most powerful sanitiser, reduce chlorine
						by up to 80%. Gentle on eyes & sensitive skin with noticeably
						softer, safer pool water.
					</p>
					<div className="text-gray-800 mt-auto">
						<a
							className="inline-flex bg-blue-500 rounded px-4 py-2 shadow-md mr-4 font-display md:font-sans font-normal"
							href="https://maytronics.com.au/ozone-swim"
						>
							Learn More
						</a>
						<a
							className="inline-flex bg-yellow-500 rounded px-4 py-2 shadow-md font-display md:font-sans font-normal"
							href="https://maytronics.com.au/shop?brand=Ozone%2520Swim"
						>
							Buy Now
						</a>
					</div>
				</div>
				<div className="grid text-gray-800 px-6 py-12 bg-white text-center place-content-center w-full">
					<EcoSwimTag className="h-24 lg:h-32 mx-auto text-mineral-teal mb-8" />
					<p className="text-lg mb-6 max-w-lg mx-auto font-display">
						Utilising salt or minerals, this market leading chlorinator with
						upgradable features will safely sanitise your pool water.
					</p>
					<div className="text-gray-800 mt-auto">
						<a
							className="inline-flex bg-blue-500 rounded px-4 py-2 shadow-md mr-4 font-display md:font-sans font-normal"
							href="https://maytronics.com.au/eco-swim"
						>
							Learn More
						</a>
						<a
							className="inline-flex bg-yellow-500 rounded px-4 py-2 shadow-md font-display md:font-sans font-normal"
							href="https://maytronics.com.au/shop?brand=Eco%2520Swim"
						>
							Buy Now
						</a>
					</div>
				</div>
			</section> */}

			{/* <section
				className="relative flex flex-col w-full justify-center items-center text-center text-white bg-gray-800"
				id="find-a-dealer"
			>
				<h2 className="text-3xl py-16 font-display font-medium">
					Find Your Local Mineral Swim
					<span className="font-sans" role="img">
						™
					</span>
					&nbsp;Dealer
				</h2>
				<div className="flex flex-row w-full flex-wrap items-center justify-center font-display pb-6 px-4">
					<div className="flex items-center justify-center w-full h-full text-dolphin-gray">
						<label
							className="text-white text-lg font-display mr-6"
							htmlFor="address"
						>
							Enter your address
						</label>
						<div className="w-64 -mb-3">
							<AddressInput
								{...{
									queryAddress,
									onQueryAddress,
									addresses,
									selectAddress,
									onSelectAddress,
									hideLabel: true,
									// postCode: true,
									placeholder: 'postcode',
								}}
							/>
						</div>
					</div>
				</div>
				<div className="flex flex-row w-full items-center justify-center">
					<div className="w-full">
						<MapBox
							{...{
								loading,
								selectAddress,
								selectType,
								dealers,
								selectDealer,
								handleSelectDealer,
								locale: 'en-AU',
								full: true,
							}}
							className="mb-0"
						/>
					</div>
				</div>
			</section> */}

			{/* <section
				className="grid grid-rows-3 md:grid-cols-3 md:grid-rows-none gap-1"
				css={[dealers && dealers[0] && dealers[0].name && tw`p-8`]}
			>
				{dealers &&
					dealers[0] &&
					dealers[0].name &&
					dealers.map((dealer, i) => (
						<div
							className="inline-flex items-start p-2 rounded-lg hover:bg-gray-100 cursor-pointer"
							css={[selectDealer === i && tw`bg-gray-200`]}
							key={dealer.name}
							onClick={() => onSelectDealer(i)}
							onKeyDown={() => onSelectDealer(i)}
						>
							<div className="">
								<div className="inline-block">
									<div className="relative flex space-y-1 w-full text-left sm:text-sm sm:leading-5">
										<div
											className="mr-2 p-1 flex items-start justify-center"
											css={css`
												width: 200px;
											`}
										>
											{dealer.logo && (
												<img
													id="dealer-logo"
													className="w-auto mx-auto mb-2 object-contain"
													alt={dealer.name}
													src={dealer.logo + `?fm=png&fl=png8&w=300&h=100`}
												/>
											)}
										</div>
										<div className="flex flex-col text-left">
											<h3 className="mb-2 font-display font-bold text-base text-gray-700">
												{dealer.name}
											</h3>
											<h4 className="mb-2 font-display text-sm text-gray-700">
												{`${dealer.address.city} ${dealer.address.postalCode}`}
											</h4>
											<div className="mb-2 font-display font-bold text-sm text-blue-500 leading-6">
												{dealer.phone}
											</div>
											<div className="mb-2 font-display font-bold text-sm text-blue-500 leading-6">
												{dealer.email && dealer.email[0]}
											</div>
											{dealer.type === 'elite' && (
												<div className="button-group inline-flex space-x-2 mr-auto">
													<a
														className="inline-flex bg-yellow-500 rounded px-4 py-2 shadow-md font-display font-semibold focus:outline-none focus:shadow-outline-yellow focus:border-yellow-300 transition ease-in-out duration-150 "
														href={
															'https://builders.mydolphin.com.au/' +
															dealer.social.minisite
														}
														target="_blank"
														rel="noreferrer noopener"
													>
														View Profile
													</a>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
			</section> */}
			</main>
		</Layout>
	)
}

export const IndexPageQuery = graphql`
	query indexPageQueryAU {
		pageData: allContentfulPage(
			filter: {
				contentful_id: { eq: "oM4c5WvwohIH0cRDqBQa4" }
				node_locale: { eq: "en-AU" }
			}
		) {
			edges {
				node {
					seoTitle
					seoKeywords
					seoDescription
					node_locale
					slug
				}
			}
		}
		slideData: allContentfulBanner(
			filter: {
				identifier: { regex: "/slide-mineral-swim-/" }
				node_locale: { eq: "en-AU" }
			}
			sort: { fields: identifier, order: ASC }
		) {
			edges {
				node {
					hook
					backgroundImage {
						title
						alt: description
						gatsbyImageData(
							layout: FULL_WIDTH
							width: 1600
							height: 900
							quality: 70
							placeholder: BLURRED
							formats: [AUTO, WEBP]
						)
					}
					objectPosition: background
					mainActionText
					mainActionUrl
					secondActionText
					secondActionUrl
					thirdActionText
					downloads {
						file {
							url
						}
					}
				}
			}
		}
		ozonePlusMineralsImage: allContentfulAsset(
			filter: {
				contentful_id: { eq: "1bywitGSiAX7ya8NIX7cGr" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description
				gatsbyImageData(layout: FIXED, width: 250, quality: 65)
			}
		}
		testimonyImage: allContentfulAsset(
			filter: {
				contentful_id: { eq: "3g0bX3OWHQ38nGTX7EW6nZ" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description
				gatsbyImageData(
					layout: FULL_WIDTH
					width: 1600
					height: 900
					quality: 70
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
		environmentImage: allContentfulAsset(
			filter: {
				contentful_id: { eq: "5xkrjj9wx0IwvqS5eKjyAz" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description
				gatsbyImageData(
					layout: FULL_WIDTH
					width: 1600
					height: 900
					quality: 70
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
		customerCareImage: allContentfulAsset(
			filter: {
				contentful_id: { eq: "7eG1exbHxwcz5duqWf5AGQ" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description
				gatsbyImageData(
					layout: FULL_WIDTH
					width: 1600
					height: 900
					quality: 70
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
		systemDiagramImage: allContentfulAsset(
			filter: {
				contentful_id: { eq: "Z0wrBpkLpa5F1ojZl0pTh" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description

				gatsbyImageData(
					layout: FULL_WIDTH
					width: 1600
					height: 900
					quality: 80
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
		systemComparisonImage: allContentfulAsset(
			filter: {
				contentful_id: { eq: "62DBO9BJm8q4KFH6S3fPgZ" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description
				gatsbyImageData(
					layout: CONSTRAINED
					width: 960
					quality: 80
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
		howItWorks3000xImage: allContentfulAsset(
			filter: {
				contentful_id: { eq: "qoCJaQtgEThV5XI5WAI6t" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description
				gatsbyImageData(
					layout: FULL_WIDTH
					width: 300
					height: 300
					quality: 80
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
		howItWorksStepsImage: allContentfulAsset(
			filter: {
				contentful_id: { eq: "3qqNdHhj1eci6lfmzFbohB" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description
				gatsbyImageData(
					layout: FULL_WIDTH
					width: 375
					height: 370
					quality: 80
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
		howItWorks80Image: allContentfulAsset(
			filter: {
				contentful_id: { eq: "2ONUUbuAkMdSm1E0L1GZ1S" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description
				gatsbyImageData(
					layout: FULL_WIDTH
					width: 300
					height: 300
					quality: 80
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
		systemDiagramMobileImage: allContentfulAsset(
			filter: {
				contentful_id: { eq: "Z0wrBpkLpa5F1ojZl0pTh" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description
				gatsbyImageData(
					layout: FULL_WIDTH
					width: 900
					height: 800
					quality: 80
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
		systemImage: allContentfulAsset(
			filter: {
				contentful_id: { eq: "2J00c5KA4b1QUa0458eIYV" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description
				gatsbyImageData(
					layout: CONSTRAINED
					width: 300
					height: 250
					quality: 80
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
		mineralsImage: allContentfulAsset(
			filter: {
				contentful_id: { eq: "5sQO2IdeAD0AiK8ZPWjEPb" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description
				gatsbyImageData(
					layout: CONSTRAINED
					width: 300
					height: 250
					quality: 70
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
		phControlImage: allContentfulAsset(
			filter: {
				contentful_id: { eq: "6E45H09MFZoTCxpSmh30wQ" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description
				gatsbyImageData(
					layout: CONSTRAINED
					width: 300
					height: 250
					quality: 70
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
		cleanerImage: allContentfulAsset(
			filter: {
				contentful_id: { eq: "4JPYUN2nsnGDmINK5gIGLn" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description
				gatsbyImageData(
					layout: CONSTRAINED
					width: 300
					height: 250
					quality: 70
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
		formData: allContentfulForm(
			filter: {
				contentful_id: { eq: "6UMqPZWkL51tUSj47XCQEJ" }
				node_locale: { eq: "en-AU" }
			}
		) {
			edges {
				node {
					title
					description
					endpoint
					node_locale
					items {
						... on ContentfulFormField {
							helpText
							inputAutocomplete
							inputId
							inputName
							inputPlaceholder
							inputRequired
							inputType
							textareaCols
							textareaRows
							icon
							validationText
							width
							options
							node_locale
							label
						}
						... on ContentfulFormGroup {
							title
							items {
								width
								validationText
								textareaRows
								textareaCols
								options
								node_locale
								label
								inputType
								inputRequired
								inputPlaceholder
								inputName
								inputId
								inputAutocomplete
								icon
								helpText
							}
						}
					}
				}
			}
		}
		indexBannerXLGatsby: allContentfulAsset(
			filter: {
				contentful_id: { eq: "gokCxRbyY6gOuh9xmpKKH" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description

				gatsbyImageData(
					layout: FULL_WIDTH
					width: 1600
					height: 900
					quality: 80
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
			indexBannerMDGatsby: allContentfulAsset(
			filter: {
				contentful_id: { eq: "fBD8UK8YWKUn2jP5olYp0" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description

				gatsbyImageData(
					layout: FULL_WIDTH
					width: 1600
					height: 900
					quality: 80
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
			indexBannerSMGatsby: allContentfulAsset(
			filter: {
				contentful_id: { eq: "6yQDvHeX6DelOfL29krtrp" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description

				gatsbyImageData(
					layout: FULL_WIDTH
					width: 700
					height: 1200
					quality: 80
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
	}
`

export default IndexPage

IndexPage.propTypes = {
	data: PropTypes.shape({
		pageData: PropTypes.objectOf(PropTypes.array),
		slideData: PropTypes.objectOf(PropTypes.array),
		ozonePlusMineralsImage: PropTypes.object,
		systemImage: PropTypes.object,
		cleanerImage: PropTypes.object,
		mineralsImage: PropTypes.object,
		phControlImage: PropTypes.object,
		testimonyImage: PropTypes.object,
		environmentImage: PropTypes.object,
		formData: PropTypes.object,
		systemComparisonImage: PropTypes.object,
		howItWorks3000xImage: PropTypes.object,
		howItWorksStepsImage: PropTypes.object,
		howItWorks80Image: PropTypes.object,
		systemDiagramImage: PropTypes.object,
		customerCareImage: PropTypes.object,
	}),
	location: PropTypes.shape({
		search: PropTypes.string,
	}),
}
